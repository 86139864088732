<template>
  <div class="school-organization-choice">
    <div class="bg"></div>
    <div class="bd">
      <div class="title">分局 / 总局</div>
      <div class="form">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
            size="large"
            v-decorator="['name',{rules: [{ required: true, message: '请输入分局或总局名称!' }]}]"
            placeholder="请输入分局或总局名称"
            >
            </a-input>
          </a-form-item>
        <div class="button">
          <a-button type="primary" size="large" html-type="submit">
            确认
          </a-button>
        </div>
        </a-form>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import footerComponent from '@/components/Footer'
import apis from '@/business/apis'
export default {
  components: {
    footerComponent
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'org_school' })
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let response = await this.$http.get(apis.school.schoolCode + '?name=' + values.name)
          if (response && response.body && response.body.code) {
            this.$router.push({ name: 'SchoolAdminLogin', params: { schoolCode: response.body.code } })
            return
          }
          response = await this.$http.get(apis.organization.orgCode + '?name=' + values.name)
          if (response && response.body && response.body.code) {
            this.$router.push({ name: 'OrgAdminLogin', params: { orgCode: response.body.code } })
            return
          }
          const arr = [{ message: '您输入分局或总局不存在!', field: 'name' }]
          this.form.setFields({ name: { value: values.name, errors: arr } })
        }
      })
    }
  }
}
</script>
<style lang="less">
.school-organization-choice {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > .bg {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    background: url(../assets/images/bg.jpg);
    background-size: cover;
    background-position: center;
  }
  .bd {
    margin-bottom: 60px;
    width: 480px;
    background-color: #fff;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 60px;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .form {
      margin: 40px 0 0 0;
      .ant-input {
          height: 48px;
        }
      .ant-form-item {
        margin-bottom: 32px;
      }
      .ant-form-explain {
        position: absolute;
        top: 55px;
      }
    }
    .button {
      padding-top:8px;
      .ant-btn {
        width: 120px;
        height: 52px;
        font-size: 16px;
        border: none;
      }
    }
  }
  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
